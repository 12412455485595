// The default environment represents local development. The contents of this file will be replaced
// during the build/serve process with the specified environment.

const isLocalHost = window.location.hostname.indexOf('localhost') > -1;
const isDevelopment = isLocalHost
    || window.location.hostname.indexOf('dev') > -1
    || window.location.hostname.endsWith('spsc.io');

const useProductionMode = !isDevelopment;
const urlSubDomain = isDevelopment
    ? '.dev'
    : '';
const baseUrl = `${window.location.protocol}://${window.location.host}`;
const cognitoClientId = isDevelopment
    ? '1mh9eiv2dn67vr5ugb651etn0h'
    : '4f6ln05ghnf68ls93vu8eqsuee';
const identityPoolId = isDevelopment
    ? 'us-east-1:766ed4b5-86b6-4237-80e7-95b2acda9c09'
    : 'us-east-1:370732b9-d5c0-40fe-abd5-032fbf47b88b';
const userPoolId = isDevelopment
    ? 'us-east-1_ylvbLKLiG'
    : 'us-east-1_OlX3FFtal';
const launchDarklyClientId = isDevelopment
    ? '61df0e8e10ca3613630c1dc6'
    : '61df0e8e10ca3613630c1dc7';
const googleAnalyticsId = isDevelopment
    ? 'UA-101872179-1'
    : 'UA-101872179-2';
const ga4MeasurementId = isDevelopment
    ? 'G-5DK3WLCQRL'
    : 'G-82X2QZ0W7J';

export const environment = {
    useProductionMode,
    urlSubDomain,
    baseUrl,
    googleAnalyticsId,
    ga4MeasurementId,
    buildVersion: '3.468',
    cognitoClientId,
    region: 'us-east-1',
    identityPoolId,
    userPoolId,
    googleAutocompleteKey: 'AIzaSyCsTgjvyTiRcWIbZSXkaolwBOVRjod74Mo',
    googleRecaptchaSiteKey: '6LdbeKEcAAAAAAD1X3nksoIYTkn4lr37zStZBJag',
    launchDarklyClientId,
    dataDog: {
        applicationId: '79698ad7-d54e-43b7-af63-b574c425be72',
        clientToken: 'pubb55a526784866787d0c12aee9202cc87',
        site: 'datadoghq.com'
    }
};
